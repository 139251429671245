// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-angststoerungen-tsx": () => import("./../../../src/pages/angststoerungen.tsx" /* webpackChunkName: "component---src-pages-angststoerungen-tsx" */),
  "component---src-pages-appointment-page-appointment-page-tsx": () => import("./../../../src/pages/AppointmentPage/AppointmentPage.tsx" /* webpackChunkName: "component---src-pages-appointment-page-appointment-page-tsx" */),
  "component---src-pages-contact-page-contact-page-tsx": () => import("./../../../src/pages/ContactPage/ContactPage.tsx" /* webpackChunkName: "component---src-pages-contact-page-contact-page-tsx" */),
  "component---src-pages-data-security-page-data-security-page-tsx": () => import("./../../../src/pages/DataSecurityPage/DataSecurityPage.tsx" /* webpackChunkName: "component---src-pages-data-security-page-data-security-page-tsx" */),
  "component---src-pages-datenschutz-tsx": () => import("./../../../src/pages/datenschutz.tsx" /* webpackChunkName: "component---src-pages-datenschutz-tsx" */),
  "component---src-pages-demenz-alzheimer-muenchen-tsx": () => import("./../../../src/pages/demenz-alzheimer-muenchen.tsx" /* webpackChunkName: "component---src-pages-demenz-alzheimer-muenchen-tsx" */),
  "component---src-pages-depression-tsx": () => import("./../../../src/pages/depression.tsx" /* webpackChunkName: "component---src-pages-depression-tsx" */),
  "component---src-pages-en-anxiety-disorders-tsx": () => import("./../../../src/pages/en/anxiety-disorders.tsx" /* webpackChunkName: "component---src-pages-en-anxiety-disorders-tsx" */),
  "component---src-pages-en-arrange-appointment-tsx": () => import("./../../../src/pages/en/arrange-appointment.tsx" /* webpackChunkName: "component---src-pages-en-arrange-appointment-tsx" */),
  "component---src-pages-en-carpal-tunnel-syndrome-tsx": () => import("./../../../src/pages/en/carpal-tunnel-syndrome.tsx" /* webpackChunkName: "component---src-pages-en-carpal-tunnel-syndrome-tsx" */),
  "component---src-pages-en-contact-tsx": () => import("./../../../src/pages/en/contact.tsx" /* webpackChunkName: "component---src-pages-en-contact-tsx" */),
  "component---src-pages-en-dementia-alzheimer-munich-tsx": () => import("./../../../src/pages/en/dementia-alzheimer-munich.tsx" /* webpackChunkName: "component---src-pages-en-dementia-alzheimer-munich-tsx" */),
  "component---src-pages-en-depression-tsx": () => import("./../../../src/pages/en/depression.tsx" /* webpackChunkName: "component---src-pages-en-depression-tsx" */),
  "component---src-pages-en-imprint-tsx": () => import("./../../../src/pages/en/imprint.tsx" /* webpackChunkName: "component---src-pages-en-imprint-tsx" */),
  "component---src-pages-en-migraine-headache-tsx": () => import("./../../../src/pages/en/migraine-headache.tsx" /* webpackChunkName: "component---src-pages-en-migraine-headache-tsx" */),
  "component---src-pages-en-multiple-sclerosis-therapy-munich-tsx": () => import("./../../../src/pages/en/multiple-sclerosis-therapy-munich.tsx" /* webpackChunkName: "component---src-pages-en-multiple-sclerosis-therapy-munich-tsx" */),
  "component---src-pages-en-neurology-tsx": () => import("./../../../src/pages/en/neurology.tsx" /* webpackChunkName: "component---src-pages-en-neurology-tsx" */),
  "component---src-pages-en-neuropsychology-tsx": () => import("./../../../src/pages/en/neuropsychology.tsx" /* webpackChunkName: "component---src-pages-en-neuropsychology-tsx" */),
  "component---src-pages-en-parkinson-therapy-tsx": () => import("./../../../src/pages/en/parkinson-therapy.tsx" /* webpackChunkName: "component---src-pages-en-parkinson-therapy-tsx" */),
  "component---src-pages-en-polyneuropathy-tsx": () => import("./../../../src/pages/en/polyneuropathy.tsx" /* webpackChunkName: "component---src-pages-en-polyneuropathy-tsx" */),
  "component---src-pages-en-practice-tsx": () => import("./../../../src/pages/en/practice.tsx" /* webpackChunkName: "component---src-pages-en-practice-tsx" */),
  "component---src-pages-en-psychiatry-tsx": () => import("./../../../src/pages/en/psychiatry.tsx" /* webpackChunkName: "component---src-pages-en-psychiatry-tsx" */),
  "component---src-pages-en-sleep-medicine-munich-tsx": () => import("./../../../src/pages/en/sleep-medicine-munich.tsx" /* webpackChunkName: "component---src-pages-en-sleep-medicine-munich-tsx" */),
  "component---src-pages-en-sports-medicine-lactatetest-tsx": () => import("./../../../src/pages/en/sports-medicine-lactatetest.tsx" /* webpackChunkName: "component---src-pages-en-sports-medicine-lactatetest-tsx" */),
  "component---src-pages-en-stroke-therapy-munich-tsx": () => import("./../../../src/pages/en/stroke-therapy-munich.tsx" /* webpackChunkName: "component---src-pages-en-stroke-therapy-munich-tsx" */),
  "component---src-pages-en-team-tsx": () => import("./../../../src/pages/en/team.tsx" /* webpackChunkName: "component---src-pages-en-team-tsx" */),
  "component---src-pages-en-traumatic-brain-injury-tsx": () => import("./../../../src/pages/en/traumatic-brain-injury.tsx" /* webpackChunkName: "component---src-pages-en-traumatic-brain-injury-tsx" */),
  "component---src-pages-gehirnerschuetterung-schaedel-hirn-trauma-tsx": () => import("./../../../src/pages/gehirnerschuetterung-schaedel-hirn-trauma.tsx" /* webpackChunkName: "component---src-pages-gehirnerschuetterung-schaedel-hirn-trauma-tsx" */),
  "component---src-pages-home-page-home-page-tsx": () => import("./../../../src/pages/HomePage/HomePage.tsx" /* webpackChunkName: "component---src-pages-home-page-home-page-tsx" */),
  "component---src-pages-illnesses-carpal-tunnel-page-carpal-tunnel-page-tsx": () => import("./../../../src/pages/Illnesses/CarpalTunnelPage/CarpalTunnelPage.tsx" /* webpackChunkName: "component---src-pages-illnesses-carpal-tunnel-page-carpal-tunnel-page-tsx" */),
  "component---src-pages-illnesses-concussion-page-concussion-page-tsx": () => import("./../../../src/pages/Illnesses/ConcussionPage/ConcussionPage.tsx" /* webpackChunkName: "component---src-pages-illnesses-concussion-page-concussion-page-tsx" */),
  "component---src-pages-illnesses-demenz-page-demenz-page-tsx": () => import("./../../../src/pages/Illnesses/DemenzPage/DemenzPage.tsx" /* webpackChunkName: "component---src-pages-illnesses-demenz-page-demenz-page-tsx" */),
  "component---src-pages-illnesses-depression-page-depression-page-tsx": () => import("./../../../src/pages/Illnesses/DepressionPage/DepressionPage.tsx" /* webpackChunkName: "component---src-pages-illnesses-depression-page-depression-page-tsx" */),
  "component---src-pages-illnesses-fear-page-fear-page-tsx": () => import("./../../../src/pages/Illnesses/FearPage/FearPage.tsx" /* webpackChunkName: "component---src-pages-illnesses-fear-page-fear-page-tsx" */),
  "component---src-pages-illnesses-migraine-page-migraine-page-tsx": () => import("./../../../src/pages/Illnesses/MigrainePage/MigrainePage.tsx" /* webpackChunkName: "component---src-pages-illnesses-migraine-page-migraine-page-tsx" */),
  "component---src-pages-illnesses-ms-page-ms-page-tsx": () => import("./../../../src/pages/Illnesses/MSPage/MSPage.tsx" /* webpackChunkName: "component---src-pages-illnesses-ms-page-ms-page-tsx" */),
  "component---src-pages-illnesses-parkinson-page-parkinson-page-tsx": () => import("./../../../src/pages/Illnesses/ParkinsonPage/ParkinsonPage.tsx" /* webpackChunkName: "component---src-pages-illnesses-parkinson-page-parkinson-page-tsx" */),
  "component---src-pages-illnesses-polyneuropathy-page-polyneuropathy-page-tsx": () => import("./../../../src/pages/Illnesses/PolyneuropathyPage/PolyneuropathyPage.tsx" /* webpackChunkName: "component---src-pages-illnesses-polyneuropathy-page-polyneuropathy-page-tsx" */),
  "component---src-pages-illnesses-stroke-page-stroke-page-tsx": () => import("./../../../src/pages/Illnesses/StrokePage/StrokePage.tsx" /* webpackChunkName: "component---src-pages-illnesses-stroke-page-stroke-page-tsx" */),
  "component---src-pages-impressum-tsx": () => import("./../../../src/pages/impressum.tsx" /* webpackChunkName: "component---src-pages-impressum-tsx" */),
  "component---src-pages-imprint-page-imprint-page-tsx": () => import("./../../../src/pages/ImprintPage/ImprintPage.tsx" /* webpackChunkName: "component---src-pages-imprint-page-imprint-page-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-jobs-page-jobs-page-tsx": () => import("./../../../src/pages/JobsPage/JobsPage.tsx" /* webpackChunkName: "component---src-pages-jobs-page-jobs-page-tsx" */),
  "component---src-pages-karpaltunnelsyndrom-tsx": () => import("./../../../src/pages/karpaltunnelsyndrom.tsx" /* webpackChunkName: "component---src-pages-karpaltunnelsyndrom-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-migraene-kopfschmerzen-tsx": () => import("./../../../src/pages/migraene-kopfschmerzen.tsx" /* webpackChunkName: "component---src-pages-migraene-kopfschmerzen-tsx" */),
  "component---src-pages-multiple-sklerose-therapie-muenchen-tsx": () => import("./../../../src/pages/multiple-sklerose-therapie-muenchen.tsx" /* webpackChunkName: "component---src-pages-multiple-sklerose-therapie-muenchen-tsx" */),
  "component---src-pages-neurologie-tsx": () => import("./../../../src/pages/neurologie.tsx" /* webpackChunkName: "component---src-pages-neurologie-tsx" */),
  "component---src-pages-neurology-page-neurology-page-tsx": () => import("./../../../src/pages/NeurologyPage/NeurologyPage.tsx" /* webpackChunkName: "component---src-pages-neurology-page-neurology-page-tsx" */),
  "component---src-pages-neuropsychologie-tsx": () => import("./../../../src/pages/neuropsychologie.tsx" /* webpackChunkName: "component---src-pages-neuropsychologie-tsx" */),
  "component---src-pages-neuropsychology-page-neuropsychology-page-tsx": () => import("./../../../src/pages/NeuropsychologyPage/NeuropsychologyPage.tsx" /* webpackChunkName: "component---src-pages-neuropsychology-page-neuropsychology-page-tsx" */),
  "component---src-pages-not-found-page-not-found-page-tsx": () => import("./../../../src/pages/NotFoundPage/NotFoundPage.tsx" /* webpackChunkName: "component---src-pages-not-found-page-not-found-page-tsx" */),
  "component---src-pages-office-page-office-page-tsx": () => import("./../../../src/pages/OfficePage/OfficePage.tsx" /* webpackChunkName: "component---src-pages-office-page-office-page-tsx" */),
  "component---src-pages-parkinson-therapie-tsx": () => import("./../../../src/pages/parkinson-therapie.tsx" /* webpackChunkName: "component---src-pages-parkinson-therapie-tsx" */),
  "component---src-pages-polyneuropathie-tsx": () => import("./../../../src/pages/polyneuropathie.tsx" /* webpackChunkName: "component---src-pages-polyneuropathie-tsx" */),
  "component---src-pages-praxis-tsx": () => import("./../../../src/pages/praxis.tsx" /* webpackChunkName: "component---src-pages-praxis-tsx" */),
  "component---src-pages-psychiatrie-tsx": () => import("./../../../src/pages/psychiatrie.tsx" /* webpackChunkName: "component---src-pages-psychiatrie-tsx" */),
  "component---src-pages-psychiatry-page-psychiatry-page-tsx": () => import("./../../../src/pages/PsychiatryPage/PsychiatryPage.tsx" /* webpackChunkName: "component---src-pages-psychiatry-page-psychiatry-page-tsx" */),
  "component---src-pages-schlafmedizin-muenchen-tsx": () => import("./../../../src/pages/schlafmedizin-muenchen.tsx" /* webpackChunkName: "component---src-pages-schlafmedizin-muenchen-tsx" */),
  "component---src-pages-schlaganfall-therapie-muenchen-tsx": () => import("./../../../src/pages/schlaganfall-therapie-muenchen.tsx" /* webpackChunkName: "component---src-pages-schlaganfall-therapie-muenchen-tsx" */),
  "component---src-pages-sleep-page-sleep-page-tsx": () => import("./../../../src/pages/SleepPage/SleepPage.tsx" /* webpackChunkName: "component---src-pages-sleep-page-sleep-page-tsx" */),
  "component---src-pages-sportmedizin-laktattest-tsx": () => import("./../../../src/pages/sportmedizin-laktattest.tsx" /* webpackChunkName: "component---src-pages-sportmedizin-laktattest-tsx" */),
  "component---src-pages-sports-page-sports-page-tsx": () => import("./../../../src/pages/SportsPage/SportsPage.tsx" /* webpackChunkName: "component---src-pages-sports-page-sports-page-tsx" */),
  "component---src-pages-stellenanzeigen-tsx": () => import("./../../../src/pages/stellenanzeigen.tsx" /* webpackChunkName: "component---src-pages-stellenanzeigen-tsx" */),
  "component---src-pages-team-tsx": () => import("./../../../src/pages/team.tsx" /* webpackChunkName: "component---src-pages-team-tsx" */),
  "component---src-pages-teams-page-teams-page-tsx": () => import("./../../../src/pages/TeamsPage/TeamsPage.tsx" /* webpackChunkName: "component---src-pages-teams-page-teams-page-tsx" */),
  "component---src-pages-terminvereinbarung-tsx": () => import("./../../../src/pages/terminvereinbarung.tsx" /* webpackChunkName: "component---src-pages-terminvereinbarung-tsx" */)
}

